import {FETCH_EVENTS} from "./types";

const initialState = {
    events: [],
}
export const eventsReducer=(state = initialState, action)=> {
    switch (action.type) {
        case FETCH_EVENTS:
            return {...state, events: action.payload}
        default:
            return state;
    }
}
