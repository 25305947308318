import {AUTHORIZATION} from "./types";

let userHash = localStorage.getItem('USER_HASH');
// console.log(userHash)
const initialState = {
    userHash,
}
export const authReducer=(state = initialState, action)=> {
    switch (action.type) {
        case AUTHORIZATION:
            return {...state, userHash: action.payload}
        default:
            return state;
    }
}
