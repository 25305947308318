import {HIDE_ALERT, SHOW_ALERT} from "./types";

const initialState ={
    message: null,
}
export const notificationReducer =(state = initialState, action)=> {
    switch (action.type) {
        case SHOW_ALERT:
            return {...state, message: action.payload}
        case HIDE_ALERT:
            return {...state, message: null}
        default:
            return state;
    }
}
