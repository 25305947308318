import React from 'react';
import {Link} from "react-router-dom";

const NoMatch=()=> {
    return (
         <div className="d-flex height justify-content-center flex-column bd-highlight mb-2">
                <div className="p-1 bd-highlight">
                    <h2>404</h2>
                </div>
                <div className="p-2 bd-highlight">Извините, произошла ошибка, запрошенная страница не найдена!</div>
                <div className="p-1 bd-highlight"><Link to="/" className="btn btn-primary btn-lg">Перейти к заявкам</Link></div>
            </div>
    )
}


export default NoMatch;
