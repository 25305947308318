import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import base64 from 'base-64';
import Checkbox from "../common/Checkbox";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchCategories,
    fetchData,
    fetchDepartment, fetchEquipment, fetchEvents, fetchTemporaryEquipment,
    fetchWorkCompleted,
    hideAlert,
    showAlert
} from "../../redux/actions";
import hours from '../../hours.json';
import Modal from "../common/not-reuse/Modal";

const OrderForm=()=> {
    let history = useHistory();
    const dispatch = useDispatch();
    const department = useSelector(state=>state.department);
    const userToken = useSelector(state=> state.auth.userHash);
    const equipment = useSelector(state=>state.equipment.equipment);
    const equip_categories = useSelector(state=>state.equip_categories.equip_categories);
    const workCompleted = useSelector(state=>state.work_completed.work_completed);
    const temporary_equipment = useSelector(state=>state.temporary_equipment.temporary_equipment);
    const [time, setTime] = useState({hh:'00', mm:'00'})
    const [selected, setSelected] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [education, setEducation] = useState("")

    useEffect( ()=> {
        dispatch(fetchEvents(userToken))
        dispatch(fetchDepartment(1));
        dispatch(fetchDepartment(2));
        dispatch(fetchTemporaryEquipment(userToken))
        dispatch(fetchCategories());
        dispatch(fetchWorkCompleted());
    },[dispatch, userToken])

    const { id } = useParams();
    const event = useSelector(state=> state.events.events.filter(item=>item.id===base64.decode(id)))[0]

    const [formData, setData] = useState({
        id: base64.decode(id),
        status: "Завершено",
        workConst: "",
        work_completed: [],
        description_work: "",
        recommendation: "",
        repairs: "",
        list_equipment_repairs: [],
        replacementequipment: temporary_equipment === null ? "0" : "",
        list_equipment_replace: [],
        TakeEquipment: event?.TakeEquipment === true ? "" : "0",
        list_equipment_take: [],
        invoice: "",
        list_equipment_invoice: [],
        kp: "",
        list_kp: {},
        create_event: "",
        list_event: [],
        education: ""
    });

    useEffect(()=> {
        setData(
            {...formData,
                workConst:parseInt(time.hh)+parseInt(time.mm)/(60)
            })
    },[time])

    const handlerCheckbox=(name, checkboxId)=>{
        if (name==='yes') {
            if (checkboxId===1) {
                setData(
                    {...formData},
                    formData.repairs='1'
                )
                addForm(checkboxId)
            }else if(checkboxId===2) {
                setData(
                    {...formData},
                    formData.invoice='1'
                )
                addForm(checkboxId)
            }else if(checkboxId===3) {
                setData(
                    {...formData},
                    formData.kp='1'
                )
                addForm(checkboxId)
            }
            else if(checkboxId===4) {
                setData(
                    {...formData},
                    formData.create_event='1'
                )
                addForm(checkboxId)
            }
            else if(checkboxId===5) {
                setData(
                    {...formData},
                    formData.replacementequipment='1'
                )
                addForm(checkboxId)
            } else if(checkboxId===6) {
                setData(
                    {...formData},
                    formData.TakeEquipment='1'
                )
                addForm(checkboxId)
            }
        } else {
            // console.log(checkboxId);
            if (checkboxId===1) {
                setData(
                    {...formData},
                    formData.repairs='0'
                )
                clearForm(checkboxId)
            }else if (checkboxId===2) {
                setData(
                    {...formData},
                    formData.invoice='0'
                )
                clearForm(checkboxId)
            }else if (checkboxId===3) {
                setData(
                    {...formData},
                    formData.kp='0'
                )
                clearForm(checkboxId)
            } else if(checkboxId===4) {
                setData(
                    {...formData},
                    formData.create_event='0'
                )
                clearForm(checkboxId)
            } else if (checkboxId===5) {
                setData(
                    {...formData},
                    formData.replacementequipment='0'
                )
                clearForm(checkboxId)
            } else if (checkboxId===6) {
                setData(
                    {...formData},
                    formData.TakeEquipment='0'
                )
                clearForm(checkboxId)
            }
        }
    }

    // функиця для добавления состояния формы
    const addForm=(checkboxId)=> {
        switch (checkboxId) {
            case 1:
                return setData({
                    ...formData,
                    list_equipment_repairs: [...formData.list_equipment_repairs, {
                        name_repairs: "",
                        defect: "",
                        completesets: "",
                        contact_name: "",
                        code_department: ""
                    }]
                })
            case 2:
                return setData({
                    ...formData,
                    list_equipment_invoice: [...formData.list_equipment_invoice,
                        {
                            code : "",
                            count: "",
                            price: ""
                        }]
                })
            case 3:
                return setData({
                    ...formData,
                    list_kp: {
                        description: '',
                        image: []
                    }
                })
            case 4:
                return setData({
                    ...formData,
                    list_event: [...formData.list_event,
                        {
                            code_department: "",
                            event_description: ""
                        }]
                })
            case 5:
                return setData({
                    ...formData,
                    list_equipment_replace: []
                })
            case 6:
                return setData({
                    ...formData,
                    list_equipment_take: []
                })
            default:
                return false;
        }
    }

    // функция для очиски состояния формы
        const clearForm=(checkboxId)=> {
            switch (checkboxId) {
                case 1:
                    return setData({
                        ...formData,
                        list_equipment_repairs: []
                    })
                case 2:
                    return setData({
                        ...formData,
                        list_equipment_invoice: []
                    })
                case 3:
                    return setData({
                        ...formData,
                        list_kp: {}
                    })
                case 4:
                    return setData({
                        ...formData,
                        list_event: []
                    })
                case 5:
                    return setData({
                        ...formData,
                        list_equipment_replace: []
                    })
                case 6:
                    return setData({
                        ...formData,
                        list_equipment_take: []
                    })
                default:
                    return false;
            }
        }


    // validation form
    let fail;
    const validation=(data)=> {
        if (data.workConst < 0.5) {
            fail = 'Трудозатраты должны быть не меньше 30 минут!'
        } else if (data.description_work ==='') {
            fail = 'Описание о выполненых работ должно быть заполнено!'
        } else if (data.repairs === '' || data.invoice === '' || data.kp === '' || data.create_event === '' || data.replacementequipment === '' || data.TakeEquipment === '') {
            fail = 'Не установлены чекбоксы!'
        }

        if (data.work_completed.length === 0) {
            fail = 'Не выбрана категория выполненных работ!'
        }

        if (data.replacementequipment === '1') {
            if (data.list_equipment_replace.length === 0) fail = 'Не выбрано оборудование!'
        }

        if (data.TakeEquipment === '1') {
            if (data.list_equipment_take.length === 0) fail = 'Не выбрано оборудование!'
        }

        if (data.repairs === '1') {
            for(let i=0;i<data.list_equipment_repairs.length; i++) {
                if(data.list_equipment_repairs[i].code_department==='' || data.list_equipment_repairs[i].defect==='' || data.list_equipment_repairs[i].completesets==='' || data.list_equipment_repairs[i].name_repairs===''||data.list_equipment_repairs[i].contact_name==='') {
                    fail = 'Поля форм обязательны для заполнения!'
                }
            }
        }

        if (data.invoice === '1') {
            for(let i=0;i<data.list_equipment_invoice.length; i++) {
                if (data.list_equipment_invoice[i].count === '' || data.list_equipment_invoice[i].code === '') {
                    fail = 'Поля форм обязательны для заполнения!'
                }
            }
        }

        if (data.kp === '1') {
            if (data.list_kp.description === '') {
                fail = 'Поля форм обязательны для заполнения!'
            }
        }

        if (data.create_event === '1') {
            for(let i=0;i<data.list_event.length; i++) {
                if (data.list_event[i].event_description === '' || data.list_event[i].code_department === '') {
                    fail = 'Поля форм обязательны для заполнения!'
                }
            }
        }

        if (fail) {
            dispatch(showAlert(fail, 'alert-success'))
            setTimeout(()=> {
                dispatch(hideAlert(null))
            }, 3000)
        } else {
            setModalShow(true)
        }
    }

    // получаем value из дочернего компонента и передаем данные в акшн для отправки на сервер
    const getValue = (e)=> {
        setData({...formData},formData.education=e.target.value)
        setModalShow(false)
        dispatch(fetchData(formData, history))
    }


    /// функция для установки суммы категории Выставить счет
    const selectChange=(value, index)=> {
        setSelected(value);
        setData(
            {...formData},
            formData.list_equipment_invoice[index].code = value
        );
        equipment.forEach(item=> {
            if (item.code === value) {
                setData(
                    {...formData},
                    formData.list_equipment_invoice[index].price = item.price,
                )
            }
        })
    }

    // отправляем запрос на получение оборудований и брасываем select оборудования
    const getEquipment = (id, index)=> {
        setSelected('');
        setData(
            {...formData},
            formData.list_equipment_invoice[index].code = '',
            formData.list_equipment_invoice[index].price = '',
        )
        dispatch(fetchEquipment(id));
    }


    function handleChange(e) {
        const { name, value } = e.target;
        setData(formData => ({ ...formData, [name]: value }));
    }

    function handleSelectChange(e) {
        const { name, value } = e.target;
        setTime(setTime => ({ ...setTime, [name]: value }));
    }

    // загружаем фото
    const uploadImage = (e) => {
        for(let i=0;i<e.target.files.length; i++){
            let image = e.target.files[i];
            setData(
                {...formData},
                formData.list_kp.image=[...formData.list_kp.image, image]
            )

        }
    }

    // фунция для установки мултиселекта
    const onChangeMultiSelect = (e) => {
        const updatedOptions = [...e.target.options]
            .filter(option => option.selected)
            .map(x => x.value);
        // console.log(updatedOptions);
        setData({
           ...formData,
            work_completed: updatedOptions
        })
    };

    // фунция для установки мултиселекта2
    const onChangeMultiSelect2 = (e) => {
        const updatedOptions = [...e.target.options]
            .filter(option => option.selected)
            .map(x => x.value);
        // console.log(updatedOptions);
        setData({
            ...formData,
            list_equipment_replace: updatedOptions
        })
    };

    // фунция для установки мултиселекта3
    const onChangeMultiSelect3 = (e) => {
        const updatedOptions = [...e.target.options]
            .filter(option => option.selected)
            .map(x => x.value);
        // console.log(updatedOptions);
        setData({
            ...formData,
            list_equipment_take: updatedOptions
        })
    };
    // console.log(formData)
    return(
        <div className='container'>
            {modalShow &&
                <Modal getValue={getValue} />
            }
            <form className='form-group mt-2' onSubmit={(e)=>{e.preventDefault(); validation(formData)}}>
                <div className="form-group form-close mb-0">
                    <label>Трудозатраты</label>
                </div>
                <div className="d-flex">
                    <div className="form-group text-left mr-4">
                        <select name="hh" value={time.hh} onChange={handleSelectChange} className="custom-select my-1 mr-sm-2">
                            <option value="00">Часы</option>
                            {hours.hours.map(hour=> (
                                <option value={hour.value}>{hour.label}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group text-left">
                        <select name="mm" onChange={handleSelectChange} value={time.mm} className="custom-select my-1 mr-sm-2">
                            <option value="00">Минуты</option>
                            <option value="15">15</option>
                            <option value="30">30</option>
                            <option value="45">45</option>
                        </select>
                    </div>
                </div>
                <div className="form-group text-left">
                    <label htmlFor="work_completed">Категория выполненных работ</label>
                    <select onChange={onChangeMultiSelect} multiple={true} className="custom-select my-1 mr-sm-2" id="work_completed">
                        {workCompleted.map(item=> (
                            <option value={item.idcat}>{item.name}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group form-close">
                    <label htmlFor="description_work">Описание выполненных работ</label>
                    <textarea className="form-control" id="description_work" name='description_work' onChange={handleChange} value={formData.description_work} rows="3"></textarea>
                </div>
                <div className="form-group form-close">
                    <label htmlFor="recommendation">Рекомендации</label>
                    <textarea className="form-control" id="recommendation" name='recommendation' onChange={handleChange} value={formData.recommendation} rows="2"></textarea>
                </div>
                <div className="form-group form-close">
                    <Checkbox title={'Забрал оборудование на ремонт'} handlerCheckbox={handlerCheckbox} checkbox={1} />
                </div>

                    {formData.list_equipment_repairs.map((item,i)=> (
                        <div key={i}>
                            <div className="form-group text-left">
                                <label htmlFor="name_repair">Наименование оборудования</label>
                                <input onChange={(e)=>{
                                    setData(
                                        {...formData},
                                        formData.list_equipment_repairs[i].name_repairs=e.target.value
                                    )
                                }} className="form-control" type="text" name="name_repair" id="name_repair" value={item.name_repairs} />
                            </div>
                            <div className="form-group text-left">
                                <label htmlFor="defect">Неисправность</label>
                                <input onChange={(e)=>{
                                    setData(
                                        {...formData},
                                        formData.list_equipment_repairs[i].defect=e.target.value
                                    )
                                }} className="form-control" type="text" name="defect" id="defect" value={item.defect}/>
                            </div>
                            <div className="form-group text-left">
                                <label htmlFor="completesets">Комплектация</label>
                                <input onChange={(e)=>{
                                    setData(
                                        {...formData},
                                        formData.list_equipment_repairs[i].completesets=e.target.value
                                    )
                                }} className="form-control" type="text" name="completesets" id="completesets" value={item.completesets}/>
                            </div>
                            <div className="form-group text-left">
                                <label htmlFor="contact_name">Контактное лицо</label>
                                <input onChange={(e)=>{
                                    setData(
                                        {...formData},
                                        formData.list_equipment_repairs[i].contact_name=e.target.value
                                    )
                                }} className="form-control" type="text" name="contact_name" id="contact_name" value={item.contact_name}/>
                            </div>

                            <div className="form-group text-left">
                                <label htmlFor="department">В какой отдел</label>
                                <select onChange={(e)=>{setData({...formData},formData.list_equipment_repairs[i].code_department=e.target.value)}} className="custom-select my-1 mr-sm-2" id="department">
                                    <option defaultValue="00">Выберите отдел</option>
                                    {department.department_repairs.map(depart=> (
                                        <option value={depart.code}>{depart.name}</option>
                                    ))}
                                </select>
                            </div>
                            {formData.list_equipment_repairs.length-1 > i && <div className="line"></div> }
                            {formData.list_equipment_repairs.length-1 === i && <div className="form-group text-left"><button disabled={formData.list_equipment_repairs.length===department.length} onClick={()=>addForm(1)} type="button" className="btn btn-support">Добавить</button></div>}
                        </div>
                    ))}

                <div className="form-group form-close">
                    <Checkbox title={'Выставить счет'} handlerCheckbox={handlerCheckbox} checkbox={2} />
                </div>

                {formData.list_equipment_invoice.map((item,i)=>(
                        <div key={i}>
                            <div className="form-group text-left">
                                <label htmlFor="department">Укажите категорию и оборудование</label>
                                <select onChange={(e)=>{getEquipment(e.target.value, i)}} className="custom-select my-1 mr-sm-2" id="department">
                                    <option defaultValue="00">Выберите категорию</option>
                                    {equip_categories.map(equip_category=> (
                                        <option value={equip_category.code}>{equip_category.name}</option>
                                    ))}
                                </select>
                                <select value={i.selected} onChange={(e)=>{selectChange(e.target.value, i)}} className="custom-select my-1 mr-sm-2" id="department">
                                    <option>Выберите оборудование</option>
                                    {equipment.map(equip=> (
                                        <option value={equip.code}>{equip.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="form-group text-left mr-2">
                                    <label htmlFor="price">Цeна</label>
                                    <input disabled className="form-control" type="text" name="price" id="price" value={item.price}/>
                                </div>
                                <div className="form-group text-left">
                                    <label htmlFor="count">Кол-во</label>
                                    <input onChange={(e)=>{
                                        setData(
                                            {...formData},
                                            formData.list_equipment_invoice[i].count=e.target.value
                                        )
                                    }} className="form-control" type="number" name="count" id="count" value={item.count}/>
                                </div>
                            </div>
                            {formData.list_equipment_invoice.length-1 > i && <div className="line"></div> }
                            {formData.list_equipment_invoice.length-1 === i && <div className="form-group text-left"><button onClick={()=>addForm(2)} type="button" className="btn btn-support">Добавить</button></div>}
                        </div>
                    ))}
                <div className="form-group form-close">
                    <Checkbox title={'Создать заявку в отдел продаж'} handlerCheckbox={handlerCheckbox} checkbox={3}/>
                </div>

                    {Object.entries(formData.list_kp).length !==0 &&
                        <>
                            <div className="form-group text-left">
                                <label htmlFor="description">Описание оборудования или услуг</label>
                                <textarea onChange={(e)=>{
                                    setData(
                                        {...formData},
                                        formData.list_kp.description=e.target.value
                                    )
                                }} value={formData.list_kp.description} className="form-control" id="description" name='description' rows="2"></textarea>
                            </div>
                            <div className="form-group text-left">
                                    <label htmlFor="imgUpload">Прикрепить изображение</label>
                                    <input multiple={true} onChange={uploadImage} type="file" name="images" className="form-control-file" id="imgUpload"/>
                                <p className="mt-2">{formData.list_kp.image.length>0?`Прикреплено: ${formData.list_kp.image.length}`: `Прикреплено: ${formData.list_kp.image.length}`}</p>
                            </div>
                        </>
                    }
                <div className="form-group form-close">
                    <Checkbox title={'Создать заявку на основании'} handlerCheckbox={handlerCheckbox} checkbox={4}/>
                </div>
                {formData.list_event.map((item,i)=>(
                    <div key={i}>
                        <div className="form-group text-left">
                            <label htmlFor="department">В какой отдел</label>
                            <select onChange={(e)=>{setData({...formData},formData.list_event[i].code_department=e.target.value)}} className="custom-select my-1 mr-sm-2" id="department">
                                <option defaultValue="00">Выберите отдел</option>
                                {department.department_events.map(depart=> (
                                    <option value={depart.code}>{depart.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group text-left">
                            <label htmlFor="description">Описание заявки</label>
                            <textarea onChange={(e)=>{
                                setData(
                                    {...formData},
                                    formData.list_event[i].event_description=e.target.value
                                )
                            }} value={formData.list_event.event_description} className="form-control" id="description" name='description' rows="2"></textarea>
                        </div>
                        {formData.list_event.length-1 > i && <div className="line"></div> }
                        {formData.list_event.length-1 === i && <div className="form-group text-left"><button onClick={()=>addForm(4)} type="button" className="btn btn-support">Добавить</button></div>}
                    </div>
                ))}
                {temporary_equipment !== null &&
                    <div className="form-group form-close">
                        <Checkbox title={'Установил оборудование на замену'} handlerCheckbox={handlerCheckbox} checkbox={5}/>
                    </div>
                }
                {formData.replacementequipment === '1' &&
                    <div className="form-group text-left">
                        <label htmlFor="replacement_equipment">Выбрать оборудование</label>
                        <select multiple={true} onChange={onChangeMultiSelect2} className="custom-select my-1 mr-sm-2" id="replacement_equipment">
                            {temporary_equipment.map(item=> (
                                <option value={item.code}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                }

                {event?.TakeEquipment &&
                    <div className="form-group form-close">
                        <Checkbox title={'Забрал подменное оборудование'} handlerCheckbox={handlerCheckbox} checkbox={6}/>
                    </div>
                }
                {formData.TakeEquipment === '1' &&
                <div className="form-group text-left">
                    <label htmlFor="take_equipment">Выбрать оборудование</label>
                    <select multiple={true} onChange={onChangeMultiSelect3} className="custom-select my-1 mr-sm-2" id="take_equipment">
                        {event.TemporaryEquipment.map(item=> (
                            <option value={item.code}>{item.name}</option>
                        ))}
                    </select>
                </div>
                }

                <div className='pb-3'>
                    <button className="btn btn-lg btn-support btn-block" type="submit">Закрыть</button>
                </div>
            </form>

        </div>
    )
}

export default OrderForm;
