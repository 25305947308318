import {FETCH_UNDELIVERED_EQUIPMENT} from "./types";

const initialState = {
    undelivered_equipment:[],
}
export const undeliveredEquipmentReducer=(state = initialState, action)=> {
    switch (action.type) {
        case FETCH_UNDELIVERED_EQUIPMENT:
            return {...state, undelivered_equipment: action.payload}
        default:
            return state;
    }
}
