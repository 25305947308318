import React, {useEffect} from 'react';
import { useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {fetchEquipmentOnClose, fetchUndeliveredEquipment} from "../../redux/actions";
import base64 from "base-64";
import Timer from "../common/Timer";


const UndeliveredOrderDetail=()=> {
    let history = useHistory();
    const dispatch = useDispatch();
    const userToken = useSelector(state=> state.auth.userHash);

    useEffect(() => {
        dispatch(fetchUndeliveredEquipment(userToken))
    }, [dispatch, userToken]);

    const { id } = useParams();

    const event = useSelector(state=> state.undelivered_equipment.undelivered_equipment.filter(item=>item.id===base64.decode(id)))[0]
    // console.log(event)
    if (event === undefined) {
        return ''
    }

    return (
        <div className="container">
            <div className="d-flex flex-column text-left">
                <div className="d-flex flex-row p-2">
                    <p className="font-weight-bold">В работе: <Timer status={"В работе"} startTime={event.startTime}/> </p>
                </div>
                <div className="d-flex flex-row p-2">
                    <p className="font-weight-bold">Партнер: <span className="text-order">{event.company.trim()}</span> </p>
                </div>
                <div className="d-flex flex-row p-2">
                    <p className="font-weight-bold">Тема события: <span className="text-order">{event.topic.trim()}</span></p>
                </div>
                <div className="d-flex flex-row p-2">
                    <p className="font-weight-bold">Описание: <span className="text-order">{event.description.trim() === '' ? 'Нет описания':event.description.trim()}</span></p>
                </div>
                <div className="d-flex flex-row p-2">
                    <p className="font-weight-bold">Контактное лицо: <span className="text-order">{event.contact}</span></p>
                </div>
                <button onClick={()=>dispatch(fetchEquipmentOnClose(event.id,"ПеренаправитьОборудование", history))} className="btn btn-lg btn-support btn-block mb-3" type="submit">Оборудование доставлено</button>
            </div>
        </div>
    )
}

export default UndeliveredOrderDetail;
