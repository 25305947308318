import React from 'react';

const Modal = (props) => {

    return (
        <div id="modal-for-form" className="modal-for-form">
            <div className="modal-content">
                <p>Данную проблему можно было решить удаленно?</p>
                <div className="d-flex justify-content-around mt-4">
                    <button onClick={props.getValue} value={1} className="btn btn-outline-light">Да</button>
                    <button onClick={props.getValue} value={0} className="btn btn-outline-light">Нет</button>
                </div>

            </div>
        </div>
    );
};

export default Modal;
