import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {fetchUser} from "../redux/actions";
import {Link} from "react-router-dom";

const TemporaryEquipment = () => {
    const dispatch = useDispatch();
    const name = useSelector(state=>state.user.name);
    const userToken = useSelector(state=> state.auth.userHash);
    const temporaryEquipment = useSelector(state=> state.temporary_equipment.temporary_equipment);

    useEffect(()=> {
        dispatch(fetchUser(userToken))
    },[dispatch, userToken])

    return (
        <div className='container'>
            <nav className="nav nav-pills nav-justified">
                <Link className="nav-link active" to={'/'}>Вернуться к заявкам</Link>
            </nav>
            <div className="d-flex flex-column">
                <div className="p-1"><h4>{name}, здравствуйте!</h4></div>
                <div className="p-1"><p>Список временного оборудования</p></div>
            </div>
            <ul className="list-group list-group-flush mt-3">
                {temporaryEquipment.map(item=> (
                    <li className="list-group-item rounded mb-2 temporary-list">{item.name}</li>
                ))}
            </ul>
        </div>
    )
}

export default TemporaryEquipment;
