import React from 'react';
import {hideAlert} from "../../redux/actions";
import {useDispatch} from "react-redux";


const Notification=({text})=> {
    const dispatch = useDispatch();

    return (
        <div className="row justify-content-center" onClick={()=>dispatch(hideAlert(null))}>
            <div className="alert alert-danger" role="alert">
                {text}
            </div>
        </div>

    )
}


export default Notification;
