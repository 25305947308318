import React, {useState, useRef} from 'react';
import { fetchEquip, hideLoader, showLoader} from "../../redux/actions";
import {useDispatch} from "react-redux";
import axiosApi from '../../axiosApi'

const EquipmentForm =()=> {
    const dispatch = useDispatch();
    const inputRef = useRef([]);
    const [data, setData] = useState({});
    const [equip, setEquip] = useState({
        id: '',
        role: '',
        id_teamviewer: ''
    });

    // получение оборудования по ID
    const getEquipmentId=()=> {
        if (equip.id === '') {
            setData({})
            setEquip({id: '',role: '', id_teamviewer: ''})
            return alert('Введите ID оборудования')
        }
        dispatch(showLoader())
        axiosApi.get('/CRM/hs/equipment/?id='+equip.id, {
            headers: {
                'Content-Type' : 'application/json',
            },
        }).then(response => {
            if (response.data.result.code === 1) {
                // console.log(response.data)
                setData(response.data)
            } else {
                alert(response.data.result.error)
                setData({})
                setEquip({...equip, role: '', id_teamviewer: ''})
            }
            dispatch(hideLoader())
        }).catch(error => {
            if (error) {
                alert('Что-то пошло не так!')
                dispatch(hideLoader())
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = {};
        Object.keys(inputRef.current).map(item=> {
            formData[item]=inputRef.current[item].value
        })
        // console.log(equip, formData)
        dispatch(fetchEquip(equip, formData))
    }
    // console.log(equip);

    return (
        <form className="form-group mt-2" id="equipment_data" onSubmit={handleSubmit}>
            {/*<p>В разработке...</p>*/}
            <div className="input-group mb-3 mt-3">
                <input value={equip.id} onChange={(e) => setEquip({...equip,id:e.target.value})} name="id_equip" id="id_equip" type="text" className="form-control" placeholder="Введите ID оборудования"/>
                    <div className="input-group-append">
                        <button onClick={getEquipmentId} className="btn btn-outline-success" type="button">Отправить</button>
                    </div>
            </div>
                {Object.entries(data).length !== 0 &&
                    <>
                    <div className="form-group text-left">
                        <label htmlFor="company_name">Наименование компании</label>
                        <input value={data.company_name.trim()} disabled={true} className="form-control" type="text" name="company_name" id="company_name"/>
                    </div>
                    <div className="form-group text-left">
                        <label htmlFor="equipment_type">Вид оборудования</label>
                        <input value={data.equipment_type.trim()} disabled={true} className="form-control" type="text" name="equipment_type" id="equipment_type"/>
                    </div>
                    {data.equipment_type === 'Сервер' &&
                        <div className="form-group text-left">
                            <label htmlFor="teamviewer">ID Teamviewer</label>
                            <input maxLength={20} onChange={(e)=>setEquip({...equip,id_teamviewer: e.target.value})} value={equip.id_teamviewer} className="form-control" type="text" name="teamviewer" id="teamviewer" required={true}/>
                        </div>
                    }
                    <div className="form-group text-left">
                        <label htmlFor="role">Роль</label>
                        <input onChange={(e)=>setEquip({...equip,role: e.target.value})} value={equip.role} className="form-control" type="text" name="role" id="role" required={true}/>
                    </div>

                    {data.equipment_characteristics.length !==0 &&
                        data.equipment_characteristics.map((item, i) => (
                            <div key={i}>
                                {Object.keys(item) == 'type' &&
                                <div className="form-group text-left">
                                    <label htmlFor="exampleFormControlSelect1">{Object.values(item)}</label>
                                    <select ref={(element) => {
                                        inputRef.current[Object.keys(item)] = element
                                    }} className="form-control" id={Object.keys(item)} required={true}>
                                        <option value="">Выберите тип</option>
                                        {data.list_types.map(item=> (
                                            <option value={item.code}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>}
                                {Object.keys(item) == 'laptop_model' &&
                                <div className="form-group text-left">
                                    <label htmlFor={Object.keys(item)}>{Object.values(item)}</label>
                                    <input ref={(element) => {
                                        inputRef.current[Object.keys(item)] = element
                                    }} className="form-control" type="text" name={Object.keys(item)}
                                           id={Object.keys(item)} required={true}/>
                                </div>}
                                {Object.keys(item) == 'cpu' &&
                                <div className="form-group text-left">
                                    <label htmlFor={Object.keys(item)}>{Object.values(item)}</label>
                                    <input ref={(element) => {
                                        inputRef.current[Object.keys(item)] = element
                                    }} className="form-control" type="text" name={Object.keys(item)}
                                           id={Object.keys(item)} required={true}/>
                                </div>}
                                {Object.keys(item) == 'operating_system' &&
                                <div className="form-group text-left">
                                    <label htmlFor={Object.keys(item)}>{Object.values(item)}</label>
                                    <input ref={(element) => {
                                        inputRef.current[Object.keys(item)] = element
                                    }} className="form-control" type="text" name={Object.keys(item)}
                                           id={Object.keys(item)} required={true}/>
                                </div>}
                                {Object.keys(item) == 'ram' &&
                                <div className="form-group text-left">
                                    <label htmlFor={Object.keys(item)}>{Object.values(item)}</label>
                                    <input ref={(element) => {
                                        inputRef.current[Object.keys(item)] = element
                                    }} className="form-control" type="text" name={Object.keys(item)}
                                           id={Object.keys(item)} required={true}/>
                                </div>}
                                {Object.keys(item) == 'hdd_sdd' &&
                                <div className="form-group text-left">
                                    <label htmlFor={Object.keys(item)}>{Object.values(item)}</label>
                                    <input ref={(element) => {
                                        inputRef.current[Object.keys(item)] = element
                                    }} className="form-control" type="text" name={Object.keys(item)}
                                           id={Object.keys(item)} required={true}/>
                                </div>}
                                {Object.keys(item) == 'server_name' &&
                                <div className="form-group text-left">
                                    <label htmlFor={Object.keys(item)}>{Object.values(item)}</label>
                                    <input ref={(element) => {
                                        inputRef.current[Object.keys(item)] = element
                                    }} className="form-control" type="text" name={Object.keys(item)}
                                           id={Object.keys(item)} required={true}/>
                                </div>}
                                {Object.keys(item) == 'role' &&
                                <div className="form-group text-left">
                                    <label htmlFor={Object.keys(item)}>{Object.values(item)}</label>
                                    <input ref={(element) => {
                                        inputRef.current[Object.keys(item)] = element
                                    }} className="form-control" type="text" name={Object.keys(item)}
                                           id={Object.keys(item)} required={true}/>
                                </div>}
                                {Object.keys(item) == 'name' &&
                                <div className="form-group text-left">
                                    <label htmlFor={Object.keys(item)}>{Object.values(item)}</label>
                                    <input ref={(element) => {
                                        inputRef.current[Object.keys(item)] = element
                                    }} className="form-control" type="text" name={Object.keys(item)}
                                           id={Object.keys(item)} required={true}/>
                                </div>}
                            </div>

                        ))

                    }
                    <div className="form-group text-left">
                        <button className="btn btn-success mb-4" type="submit">Отправить</button>
                    </div>
                    </>
                }

        </form>
    )
}

export default EquipmentForm;
