import {FETCH_USER} from "./types";

const initialState = {
    name: '',
    rate: '',
    work: {}
}
export const userReducer=(state = initialState, action)=> {
    switch (action.type) {
        case FETCH_USER:
            return {
                ...state,
                name: action.payload.username,
                rate: action.payload.rate,
                work: {
                    imWorking: action.payload.ImWorking,
                    readyOrder: action.payload.readyOrder
                }
            }
        default:
            return state;
    }
}
