import React, {useEffect} from 'react';
import Timer from "../common/Timer";
import {Link} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import {fetchEvents} from "../../redux/actions";
import base64 from 'base-64'


const Order =(props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchEvents(props.userHash))
    }, [dispatch, props.userHash]);

    // if (!props.events) {
    //     return <div className="spinner-border text-light" role="status">
    //         <span className="sr-only">Loading...</span>
    //     </div>
    // }
    if (props.events === null) {
        return <p className="font-weight-bold">Нет заявок</p>
    }

    return (
        <div className="list-group text-left">
            {props.events.map((item,idx)=> (
                <Link to={`/order-detail/${base64.encode(item.id)}`} className='order-link' key={idx}>
                    <div className={item.status !=='В работе' ? "d-flex justify-content-between align-items-center list-group-item list-group-item-action item-order color" : "d-flex justify-content-between align-items-center list-group-item list-group-item-action item-order"}>
                        <div>
                            <h5>{item.company.name.trim()}</h5>
                            <p className="font-weight-normal text-dark">{item.topic.trim() === '' ? 'Нет описания':item.topic.trim().replace('=','').slice(0,50)+'...' }</p>
                        </div>
                        <Timer status={item.status} startTime={item.startTime}/>
                    </div>
                </Link>
            ))}
        </div>
    )
}


const mapStateToProps = state => {
        return {
            events: state.events.events,
            userHash: state.auth.userHash,
        }

}

export default connect(mapStateToProps, null)(Order);

