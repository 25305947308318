import React, {useEffect, useState} from 'react';
import {changeStatusWork} from "../../../redux/actions";
import {useDispatch, useSelector} from "react-redux";

const ModalHome = ({work}) => {

    const [modal, setModal] = useState(false)
    const [imWorking, setImWorking] = useState(null)
    const [readyOrder, setReadyOrder] = useState(null)
    const dispatch = useDispatch();
    const userToken = useSelector(state=> state.auth.userHash);

    useEffect(()=> {
        setImWorking(work.imWorking)
        setReadyOrder(work.imWorking ? work.readyOrder : false)
    },[work])

    return (
        <>
            <button type="button" className={"btn btn-"+(!work.imWorking ? "danger" : !work.readyOrder ? "primary" : "success")} onClick={()=>setModal(true)}>
                {!work.imWorking ? "Вы не на работе"
                    : !work.readyOrder ? "Вы не принимаете заявки"
                        : "Вы принимаете заявки"}
            </button>

            {modal &&
                <div id="modal-for-form" className="modal-for-form">
                    <div className="modal-content">
                        <div className="form-group form-check">
                            <input onChange={()=> {
                                setImWorking(!imWorking)
                                setReadyOrder(imWorking ? false : readyOrder)
                            }} checked={imWorking} type="checkbox" className="form-check-input" id="imWorking"/>
                            <label className="form-check-label" htmlFor="imWorking">Я на работе</label>
                        </div>
                        <div className="form-group form-check">
                            <input disabled={!imWorking} onChange={()=>setReadyOrder(!readyOrder)} checked={readyOrder} type="checkbox" className="form-check-input" id="readyOrder"/>
                            <label className="form-check-label" htmlFor="readyOrder">Готов принимать заявки</label>
                        </div>
                        <div className="d-flex justify-content-around mt-4">
                            <button onClick={()=>setModal(false)} className="btn btn-outline-light">Отмена</button>
                            <button onClick={()=>dispatch(changeStatusWork(userToken, imWorking, readyOrder))} className="btn btn-outline-light">Обновить</button>
                        </div>
                    </div>
                </div>
            }

        </>
    );
};

export default ModalHome;