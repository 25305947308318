import React, {useState} from 'react'

const Checkbox=(props)=> {

    const [active, setActive] = useState({
        yes: false,
        no: false
    })

    // console.log(checkboxes)
    const handleCheckbox=(e)=> {
        const {name, checked}= e.target;
        if (name==='yes') {
            if(!active.yes===true) {
                setActive({...active,[name]:checked, no: false})
                props.handlerCheckbox(name, props.checkbox);
            }
        }else {
            if(!active.no===true) {
                setActive({...active, [name]: checked, yes: false})
                props.handlerCheckbox(name, props.checkbox);
            }
        }
    }
    return (
        <div className="form-group">
            <label htmlFor="formGroupExampleInput">{props.title}</label>
            <div className="form-check d-flex align-items-center">
                <input onChange={handleCheckbox} name='yes' className="form-check-input checkbox" type="checkbox" checked={active.yes}/>
                <label className="form-check-label ml-2">
                    Да
                </label>
            </div>
            <div className="form-check d-flex align-items-center">
                <input onChange={handleCheckbox} name='no' className="form-check-input checkbox" type="checkbox" checked={active.no}/>
                <label className="form-check-label ml-2">
                    Нет
                </label>
            </div>
        </div>
    )
}

export default Checkbox;
