import { FETCH_CATEGORIES} from "./types";

const initialState = {
    equip_categories: []
}

export const categoriesReducer =(state= initialState,action)=> {
    switch (action.type) {
        case FETCH_CATEGORIES:
            return {...state, equip_categories: action.payload}
        default:
            return state;
    }
}
