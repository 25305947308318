import {FETCH_DEPARTMENT_EVENT, FETCH_DEPARTMENT_REPAIR} from "./types";

const initialState = {
    department_repairs:[],
    department_events:[],
}
export const departmentReducer=(state = initialState, action)=> {
    switch (action.type) {
        case FETCH_DEPARTMENT_REPAIR:
            return {...state, department_repairs: action.payload}
        case FETCH_DEPARTMENT_EVENT:
            return {...state, department_events: action.payload}
        default:
            return state;
    }
}
