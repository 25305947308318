import { FETCH_WORK_COMPLETED} from "./types";

const initialState = {
   work_completed: []
}

export const workCompletedReducer =(state= initialState,action)=> {
    switch (action.type) {
        case FETCH_WORK_COMPLETED:
            return {...state, work_completed: action.payload}
        default:
            return state;
    }
}
