import {FETCH_EQUIPMENT} from "./types";

const initialState = {
    equipment:[],
}
export const equipmentReducer=(state = initialState, action)=> {
    switch (action.type) {
        case FETCH_EQUIPMENT:
            return {...state, equipment: action.payload}
        default:
            return state;
    }
}
